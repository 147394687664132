import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia';
import VueGtag from 'vue-gtag-next';
import gads from '@/plugins/gads';

import './style.scss';

import createContainerRepositories from './repositories/repository.container';

const pinia = createPinia();
const app = createApp(App);

app
  .use(createContainerRepositories)
  .use(pinia)
  .use(router)
  .use(gads, {
    property_id: 'AW-11021952114',
    conversion_track_id: 'AW-11021952114/w5yVCKGNzoEYEPLI1ocp'
  })
  .mount('#app');
