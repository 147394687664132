import { AxiosFactory, CustomAxiosResponse } from '@/repositories/axios.factory';
import { ResponsePayRequest } from './payments.response';
import { PaymentModel } from '@/domain/payment.model';

export interface PaymentPayload {
  request_id: string;
  payment_provider: string;
  payment_provider_token?: string;
}

export class PaymentsRepository extends AxiosFactory {
  constructor() {
    super('/payments');
  }

  async payRequest(input: PaymentPayload): Promise<CustomAxiosResponse<ResponsePayRequest>> {
    return await this.axiosApi.post('/', { ...input });
  }

  async verificationRequest(payment_id: string): Promise<CustomAxiosResponse<PaymentModel>> {
    return await this.axiosApi.put('/' + payment_id);
  }
}
