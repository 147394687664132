import { AxiosFactory, CustomAxiosResponse } from '@/repositories/axios.factory';
import { PaymentModel } from '@/domain/payment.model';
import { ResponseRequest } from './requests.response';

export interface CreateRequestDto {
  user_email: string;
  product_rel_id: string;
  request_matricula: string;
  request_fast: boolean;
  request_type_matricula: string;
}

export class RequestsRepository extends AxiosFactory {
  constructor() {
    super('/requests');
  }

  async createRequest(form: CreateRequestDto): Promise<CustomAxiosResponse<ResponseRequest>> {
    return await this.axiosApi.post('/', form);
  }
  async updateRequest(request_id: string, form: CreateRequestDto): Promise<CustomAxiosResponse<ResponseRequest>> {
    return await this.axiosApi.put('/' + request_id, form);
  }
}
